import clsx from 'clsx';
import React from 'react';

import { ESocialMediaIconStyle, ESocialMediaType } from '../SocialMedia';
import SocialMedia, { TSocialMediaProps } from '../SocialMedia/SocialMedia';

export type TSocialMediaListProps = {
  companyLinks?: {
    facebookUrl?: string;
    twitterUrl?: string;
    linkedinUrl?: string;
    telegramUrl?: string;
    instagramUrl?: string;
    youtubeUrl?: string;
    weiboUrl?: string;
    weChatUrl?: string;
    youkoUrl?: string;
  };
  iconStyle?: TSocialMediaProps['iconStyle'];
};

const SocialMediaList: React.FC<TSocialMediaListProps> = props => {
  const { companyLinks, iconStyle = ESocialMediaIconStyle.Original } = props;

  if (!companyLinks) {
    return null;
  }

  return (
    <div
      className={clsx('flex flex-row flex-wrap', {
        'gap-8 xl:py-[0.875rem]': iconStyle === ESocialMediaIconStyle.Original,
        'gap-x-12 gap-y-8 md:gap-x-10':
          iconStyle === ESocialMediaIconStyle.Black,
      })}
    >
      {Object.entries(companyLinks).map(([key, url]) => {
        return url ? (
          <SocialMedia
            key={key}
            currentUrl={url}
            socialPlatform={key as ESocialMediaType}
            iconStyle={iconStyle}
          />
        ) : null;
      })}
    </div>
  );
};

export default SocialMediaList;
